import { Button as MantineButton, ButtonProps } from '@mantine/core';

interface IButton extends ButtonProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: any;
  onClick?: () => void;
  id?: string;
}

const Button: React.FC<IButton> = (props) => {
  const { color = 'pink', radius = 'lg', size = 'lg', fullWidth = true, id } = props;

  return (
    <MantineButton
      {...props}
      color={color}
      radius={radius}
      size={size}
      fullWidth={fullWidth}
      id={id}
    />
  );
};

export default Button;
