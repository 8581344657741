import { gql } from '@apollo/client';

const SupplierActivitiesQuery = gql`
  query (
    $postcode: String
    $ageStart: Int
    $ageEnd: Int
    $dayOfTheWeek: [ActivityDayOfWeekFilterEnum]
    $supplierSlug: String!
    $text: String
    $distance: Int
    $offset: Int
    $limit: Int
    $time: ActivityTimeEnum
    $categories: [UUID!]
    $isOnline: Boolean
  ) {
    marketplaceActivities(
      postcode: $postcode
      ageStart: $ageStart
      ageEnd: $ageEnd
      dayOfTheWeek: $dayOfTheWeek
      supplierSlug: $supplierSlug
      text: $text
      distance: $distance
      offset: $offset
      limit: $limit
      time: $time
      categories: $categories
      isOnline: $isOnline
    ) {
      total
      latitude
      longitude
      items {
        id
        slug
        name
        cloudinaryImageId
        callToAction
        supplierName
        supplierId
        location
        shortLocation
        postcode
        ageRange
        activityType
        bookingType
        times
        weekdays
        dateRange
        supplierId
        isOnline
        categories {
          id
          name
        }
        anySpotsLeft
        isInPast
        eligibleChildren {
          id
          fullName
        }
        longitude
        latitude
        socialProof {
          limitedSpots
          popular
          justAdded
        }
        priceRange
        nextSessionDate
      }
    }
    marketplaceSupplierBySlug(supplierSlug: $supplierSlug) {
      id
      slug
      name
      cloudinaryImageId
      location {
        latitude
        longitude
      }
      seoPageTitle
      seoPageDescription
    }
  }
`;

export default SupplierActivitiesQuery;
